import React from 'react'
import { graphql } from "gatsby"
import { useSiteMetadata } from '../components/SiteData'
import AppLayout from "../components/AppLayout";
import RouterLink from '../components/shared/RouterLink'
import FeatureContentPage from '../components/FeatureContentPage'
import * as PropTypes from 'prop-types'
import PageInfo from '../components/PageInfo'
import { makeStyles } from '@material-ui/core/styles'
import { SchemaMarkupArticle } from '../components/common/SchemaMarkup'

const dateOptions = {
	// weekday: 'long',
	year: 'numeric',
	month: 'long',
	day: 'numeric'
};

const dateLocale = 'en-US';

function dateToString(date) {
	if(typeof date === 'string') {
		date = new Date(date);
	}
	return date.toLocaleDateString(dateLocale, dateOptions);
}

export function BlogPostTitle({post}) {
	return (
		<>
			{post.title || 'Blog Post'}
		</>
	);
}
BlogPostTitle.propTypes = {
	post: PropTypes.shape({
		title: PropTypes.string,
	}),
}

export function BlogPostByLine({post}) {
	let {author, date, lastModified} = post;

	let byLine = 'posted';
	if(author) {
		byLine += ' by ' + author;
	}
	if(date) {
		byLine += ' on ' + dateToString(date);
	}
	if(lastModified) {
		byLine += ' — last updated ' + dateToString(lastModified);
	}

	return (byLine !== '') ? (
		<p>
			<small>
				{byLine}
			</small>
		</p>
	) : null;
}
BlogPostByLine.propTypes = {
	post: PropTypes.shape({
		author: PropTypes.string,
		date: PropTypes.string,
		lastModified: PropTypes.string,
	}),
}

const useBlogPostStyles = makeStyles(theme => ({
	blogPost: {
		// margin: "0 auto",
		maxWidth: "800px",
		marginBottom: theme.spacing(6),
		"& figcaption": {
			marginTop: "-20px",
			padding: "0 8px",
			fontSize: "0.9em",
			fontStyle: "italic",
		},
		"& h1,h2,h3,h4,h5,h6": {
			marginTop: "1.2em",
		},
	},
	callToAction: {
		maxWidth: "800px",
		margin: [[theme.spacing(4), 0]],
	},
}), {name: "BlogPost"});

export default function BlogPost(props) {
	let {data} = props;
	let {productName} = useSiteMetadata();

	const classes = useBlogPostStyles();

	let {markdownRemark} = data;
	let {html, frontmatter} = markdownRemark;
	let {label, title, pageTitle, previewImage, date, lastModified, author, description, callToAction} = frontmatter;

	let post = {
		label: label,
		title: title,
		pageTitle: pageTitle,
		author: author,
		date: date,
		lastModified: lastModified,
		description: description,
		callToAction: callToAction,
	};

	const deFactoTitle = post.title ? post.title + " | " + productName : productName + " Blog Post";
	const markupTitle = post.title ? post.title : "Blog Post";
	const pageInfoTitle = pageTitle ? pageTitle : deFactoTitle;

	const localThumbnailUri = previewImage ? previewImage.childImageSharp.gatsbyImageData.src : undefined;

	return (
		<AppLayout>
			<SchemaMarkupArticle
				headline={markupTitle}
				isBlogPost
				localThumbnailUri={localThumbnailUri}
				datePublished={date}
				dateModified={lastModified}
			/>

			<article>
				<FeatureContentPage
					title={(<BlogPostTitle post={post}/>)}
					description={(<BlogPostByLine post={post}/>)}
				>
					<PageInfo
						title={pageInfoTitle}
						description={post.description}
					/>

					<div className="container-fluid px-sm-5 mb-5">
						<div className={classes.blogPost} dangerouslySetInnerHTML={{ __html: html }} />
						<p><RouterLink to="/blog/" suppressDecoration>&larr; Back to Blog List</RouterLink></p>
					</div>
				</FeatureContentPage>
			</article>
		</AppLayout>
	);
}

export const query = graphql`query ($slug: String!) {
  markdownRemark(fields: {slug: {eq: $slug}}) {
    html
    frontmatter {
      label
      title
      pageTitle
      previewImage { childImageSharp { gatsbyImageData(width: 700, placeholder: BLURRED, layout: CONSTRAINED) } }
      date
      lastModified
      author
      description
      callToAction
    }
  }
}
`;
